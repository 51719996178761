import styled from "@emotion/styled/macro";
import { AppBox, theme } from "@talkouttech/portal-shared";

export const InfoBoxWrapper = styled(AppBox)<{ color?: string }>`
  padding: ${theme.spacing(1)} ${theme.spacing(2)};
  justify-content: center;
  ${({ color }) =>
    color &&
    `
    border-color: ${color};
    color: ${color};
  `}
`;
