import { passwordValidation } from "validations";
import * as yup from "yup";

export const changePasswordSchema = yup.object({
  oldPassword: passwordValidation,
  newPassword: passwordValidation,
  repeatNewPassword: passwordValidation.oneOf([yup.ref("newPassword")], "errors.passwordMatch")
});

export const setPasswordSchema = yup.object({
  password: passwordValidation,
  repeatPassword: passwordValidation.oneOf([yup.ref("password")], "errors.passwordMatch")
});
