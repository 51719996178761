import { useGetTenantBranding } from "application/tenant";

export interface SvgProps {
  id: string;
  width?: number;
  height?: number;
  darkText?: boolean;
}

export const Logo = ({ id, width = 140, height = 26, darkText }: SvgProps) => {
  const { logoUrl } = useGetTenantBranding();

  if (logoUrl) {
    return <img src={logoUrl} width={width} height={height} style={{ objectFit: "contain" }} alt="Logo" />;
  }

  const textColor = darkText ? "black" : "white";

  return (
    <svg
      width={width}
      preserveAspectRatio="xMinYMin meet"
      height={height}
      viewBox="0 0 651 176"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_96_5413)">
        <mask
          id="mask0_96_5413"
          style={{
            maskType: "luminance"
          }}
          maskUnits="userSpaceOnUse"
          x={0}
          y={0}
          width={911}
          height={168}
        >
          <path d="M911 0H0V168H911V0Z" fill="white" />
        </mask>
        <g mask="url(#mask0_96_5413)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.29964e-06 83.612C11.4472 67.6886 30.1332 57.3185 51.2412 57.3185C86.0711 57.3185 114.306 85.5537 114.306 120.384C114.306 129.163 112.512 137.523 109.271 145.118L81.4319 160.855C45.0504 181.42 -1.82678e-06 155.136 0 113.344L1.29964e-06 83.612Z"
            fill="#7AADF4"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M51.2412 110.681C86.0711 110.681 114.306 82.4462 114.306 47.6162C114.306 38.8369 112.512 30.4765 109.271 22.8817L81.4319 7.14505C45.0504 -13.4204 3.12642e-06 12.8637 1.29964e-06 54.6555L0 84.3879C11.4472 100.311 30.1332 110.681 51.2412 110.681Z"
            fill="#A980EC"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M133.342 131.51C170.301 110.618 170.301 57.381 133.342 36.4891L109.002 22.7302C81.4157 29.4628 60.9414 54.3401 60.9414 83.9994C60.9414 113.659 81.4158 138.536 109.002 145.269L133.342 131.51Z"
            fill="#33C3A1"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M66.0605 59.0701C93.7391 65.7369 114.304 90.6587 114.304 120.384C114.304 129.239 112.479 137.667 109.185 145.314C81.5066 138.647 60.9414 113.725 60.9414 84C60.9414 75.1453 62.7663 66.7168 66.0605 59.0701Z"
            fill="#33C3A1"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.015625 84.4101C11.4071 68.036 30.3626 57.3186 51.8212 57.3186C72.9194 57.3186 91.5979 67.6791 103.046 83.5899C91.6549 99.964 72.6995 110.681 51.2409 110.681C30.1426 110.681 11.4642 100.321 0.015625 84.4101Z"
            fill="#7938E1"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M66.0605 108.93C93.7391 102.263 114.304 77.3415 114.304 47.6165C114.304 38.7617 112.479 30.3332 109.185 22.6865C81.5066 29.3533 60.9414 54.2751 60.9414 84.0002C60.9414 92.855 62.7663 101.283 66.0605 108.93Z"
            fill="#7E79D7"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M66.0606 108.93C62.7664 101.283 60.9414 92.8549 60.9414 84.0002C60.9414 75.1454 62.7663 66.7168 66.0606 59.0701C81.1212 62.6977 94.0756 71.73 102.757 84.0001C94.0756 96.2702 81.1212 105.303 66.0606 108.93Z"
            fill="#6A6DD5"
          />
          <path
            d="M249.49 128.556H265.497L236.968 96.2829C252.072 93.8302 261.753 82.9865 261.753 67.4955C261.753 49.8099 249.231 38.1917 230.255 38.1917H188.688V128.556H201.209V96.7993H221.735L249.49 128.556ZM230.513 49.939C242.131 49.939 249.231 56.7808 249.231 67.4955C249.231 78.3392 242.131 85.0519 230.513 85.0519H201.209V49.939H230.513Z"
            fill={textColor}
          />
          <path
            d="M304.019 63.4936C284.526 63.4936 271.359 77.0482 271.359 96.6702C271.359 116.938 284.784 130.234 305.31 130.234C316.282 130.234 326.61 125.974 332.677 119.003L324.932 111.645C321.188 116.163 313.313 119.132 305.568 119.132C293.304 119.132 284.913 112.419 283.751 101.447H336.55C338.357 77.9519 325.964 63.4936 304.019 63.4936ZM304.406 74.2082C316.283 74.2082 324.157 80.921 325.19 91.2483H283.88C285.817 80.5337 293.175 74.2082 304.406 74.2082Z"
            fill={textColor}
          />
          <path
            d="M378.902 63.4936C359.409 63.4936 346.242 77.0482 346.242 96.6702C346.242 116.938 359.667 130.234 380.193 130.234C391.166 130.234 401.493 125.974 407.56 119.003L399.815 111.645C396.071 116.163 388.197 119.132 380.451 119.132C368.187 119.132 359.796 112.419 358.635 101.447H411.433C413.24 77.9519 400.848 63.4936 378.902 63.4936ZM379.289 74.2082C391.166 74.2082 399.04 80.921 400.073 91.2483H358.764C360.7 80.5337 368.058 74.2082 379.289 74.2082Z"
            fill={textColor}
          />
          <path d="M425.385 128.556H437.391V38.1917H425.385V128.556Z" fill={textColor} />
          <path
            d="M502.492 64.7845L489.196 96.5411L481.708 114.872L474.867 96.5411L462.474 64.7845H449.565L474.996 128.169L461.957 158.505H475.125L515.401 64.7845H502.492Z"
            fill={textColor}
          />
          <path
            d="M540.537 117.196L575.908 69.5609V64.7845H524.271V76.0155H557.319L522.077 123.65V128.556H576.166V117.196H540.537Z"
            fill={textColor}
          />
          <path
            d="M617.923 63.4936C598.43 63.4936 585.263 77.0482 585.263 96.6702C585.263 116.938 598.689 130.234 619.214 130.234C630.187 130.234 640.514 125.974 646.582 119.003L638.836 111.645C635.093 116.163 627.218 119.132 619.472 119.132C607.209 119.132 598.818 112.419 597.656 101.447H650.454C652.262 77.9519 639.869 63.4936 617.923 63.4936ZM618.311 74.2082C630.187 74.2082 638.062 80.921 639.094 91.2483H597.785C599.721 80.5337 607.08 74.2082 618.311 74.2082Z"
            fill={textColor}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.29917e-06 83.5776C11.4427 67.6611 30.1208 57.2957 51.22 57.2957C86.036 57.2957 114.26 85.5196 114.26 120.336C114.26 129.112 112.467 137.469 109.226 145.061L81.3994 160.791C45.0325 181.348 -1.82604e-06 155.074 0 113.299L1.29917e-06 83.5776Z"
            fill="#7AADF4"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M51.22 110.637C86.036 110.637 114.26 82.4132 114.26 47.5972C114.26 38.8211 112.467 30.4639 109.226 22.872L81.3993 7.14218C45.0324 -13.415 3.12522e-06 12.8586 1.29917e-06 54.6336L0 84.3552C11.4427 100.272 30.1208 110.637 51.22 110.637Z"
            fill="#A980EC"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M108.96 145.211L133.29 131.457C170.235 110.574 170.235 57.3582 133.29 36.4746L108.96 22.7212C81.3844 29.4508 60.918 54.3183 60.918 83.9661C60.918 113.614 81.3844 138.481 108.96 145.211Z"
            fill="#7DD9C3"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M109.142 145.256C81.4749 138.592 60.918 113.68 60.918 83.9666C60.918 75.1154 62.7421 66.6903 66.035 59.0466C93.7025 65.7108 114.259 90.6226 114.259 120.336C114.259 129.187 112.435 137.612 109.142 145.256Z"
            fill="#53BDC2"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.015625 84.3765C11.4026 68.0089 30.3504 57.2958 51.8005 57.2958C72.8903 57.2958 91.5612 67.6521 103.005 83.5566C91.6183 99.9241 72.6704 110.637 51.2204 110.637C30.1306 110.637 11.4596 100.281 0.015625 84.3765Z"
            fill="#734BE4"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M66.035 108.887C93.7025 102.223 114.259 77.3107 114.259 47.5975C114.259 38.7463 112.435 30.3211 109.142 22.6775C81.4749 29.3416 60.918 54.2535 60.918 83.9667C60.918 92.8179 62.7421 101.243 66.035 108.887Z"
            fill="#7E79D7"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M66.037 59.0466C81.0915 62.6728 94.0408 71.7015 102.719 83.9666C94.0408 96.2317 81.0915 105.26 66.0369 108.886C62.7441 101.243 60.9199 92.8178 60.9199 83.9666C60.9199 75.1154 62.7441 66.6903 66.037 59.0466Z"
            fill="#6A6DD5"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.32338e-06 85.135C11.6559 68.9219 30.6821 58.3633 52.1744 58.3633C87.6391 58.3633 116.389 87.1131 116.389 122.578C116.389 131.517 114.562 140.03 111.262 147.764L82.9162 163.787C45.8716 184.727 -1.86007e-06 157.964 0 115.41L1.32338e-06 85.135Z"
          fill="#7AADF4"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M52.1744 112.699C87.6391 112.699 116.389 83.9488 116.389 48.4841C116.389 39.5445 114.562 31.0316 111.262 23.2982L82.9161 7.27526C45.8715 -13.665 3.18345e-06 13.0982 1.32338e-06 55.6517L0 85.927C11.6559 102.14 30.6821 112.699 52.1744 112.699Z"
          fill="#A980EC"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M110.992 147.917L135.776 133.907C173.408 112.634 173.408 58.4271 135.776 37.1544L110.992 23.1447C82.9025 29.9997 62.0547 55.3306 62.0547 85.5307C62.0547 115.731 82.9025 141.062 110.992 147.917Z"
          fill="#7DD9C3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M67.2671 60.1469C95.4501 66.9352 116.39 92.3112 116.39 122.578C116.39 131.594 114.532 140.176 111.178 147.962C82.9947 141.174 62.0547 115.798 62.0547 85.5312C62.0547 76.5151 63.9128 67.9329 67.2671 60.1469Z"
          fill="#53BDC2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.015625 85.9487C11.6147 69.2762 30.9157 58.3634 52.7654 58.3634C74.2482 58.3634 93.2671 68.9127 104.924 85.1135C93.3252 101.786 74.0242 112.699 52.1745 112.699C30.6917 112.699 11.6729 102.15 0.015625 85.9487Z"
          fill="#734BE4"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M67.2671 110.916C95.4501 104.127 116.39 78.7514 116.39 48.4846C116.39 39.4684 114.532 30.8863 111.178 23.1002C82.9947 29.8885 62.0547 55.2646 62.0547 85.5314C62.0547 94.5476 63.9128 103.13 67.2671 110.916Z"
          fill="#7E79D7"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M67.2671 60.1467C82.6017 63.8403 95.792 73.0369 104.632 85.53C95.7923 98.0239 82.6019 107.221 67.267 110.915C63.9128 103.129 62.0547 94.5471 62.0547 85.5311C62.0547 76.5149 63.9128 67.9328 67.2671 60.1467Z"
          fill="#6A6DD5"
        />
      </g>
      <defs>
        <clipPath id="clip0_96_5413">
          <rect width={651} height={176} fill={textColor} />
        </clipPath>
      </defs>
    </svg>
  );
};
