import { Typography } from "@mui/material";
import { theme } from "@talkouttech/portal-shared";
import { InfoBoxWrapper } from "components/InfoBox/InfoBox.styles";

interface InfoBoxProps {
  info: string;
  color?: string;
  textVariant?: "mediumBody1" | "caption";
}

const InfoBox = ({ info, color, textVariant }: InfoBoxProps) => {
  return (
    <InfoBoxWrapper variant="infoBox" color={color}>
      <Typography variant={textVariant ?? "mediumBody1"} color={color ?? theme.colors.primary}>
        {info}
      </Typography>
    </InfoBoxWrapper>
  );
};

export { InfoBox };
