import { yupResolver } from "@hookform/resolvers/yup";
import { Stack, Typography } from "@mui/material";
import { AppButton } from "@talkouttech/portal-shared";
import { useChangePassword } from "application/auth/auth";
import { PasswordFormField } from "components/PasswordFormField";
import { FC } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { changePasswordSchema } from "schemas/auth";

interface ChangePasswordFormData {
  oldPassword: string;
  newPassword: string;
  repeatNewPassword: string;
}

interface Props {
  buttonVariant?: "contained" | "containedSecondary";
  successCallback?(): void;
  hideHeader?: boolean;
  width?: string | number;
}

const ChangePasswordForm: FC<Props> = ({ buttonVariant, successCallback, hideHeader, width }) => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset
  } = useForm<ChangePasswordFormData>({
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(changePasswordSchema)
  });
  const { mutate, isLoading } = useChangePassword({ callback: successCallback ?? reset });

  const onSubmit = (data: ChangePasswordFormData) => {
    mutate({ password: data.newPassword, oldPassword: data.oldPassword });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack display="flex" width={width}>
        {!hideHeader && (
          <Typography variant="body3" fontWeight={700} mb={1}>
            {t("worlds.changePassword")}
          </Typography>
        )}
        <Stack display="flex" spacing={2} width={width}>
          <PasswordFormField
            name="oldPassword"
            control={control}
            defaultValue=""
            label={t("worlds.oldPassword")}
            placeholder={t("worlds.oldPassword")}
            error={!!errors.oldPassword}
            helperText={t(errors.oldPassword?.message || "")}
          />
          <PasswordFormField
            name="newPassword"
            control={control}
            defaultValue=""
            label={t("worlds.newPassword")}
            placeholder={t("worlds.newPassword")}
            error={!!errors.newPassword}
            helperText={t(errors.newPassword?.message || "")}
          />
          <PasswordFormField
            name="repeatNewPassword"
            control={control}
            defaultValue=""
            label={t("worlds.repeatNewPassword")}
            placeholder={t("worlds.repeatNewPassword")}
            error={!!errors.repeatNewPassword}
            helperText={t(errors.repeatNewPassword?.message || "")}
          />
          <AppButton
            variant={buttonVariant ?? "containedSecondary"}
            type="submit"
            isLoading={isLoading}
            disabled={isLoading}
          >
            {t("worlds.setPassword")}
          </AppButton>
        </Stack>
      </Stack>
    </form>
  );
};

export { ChangePasswordForm };
