import { useTenant } from "hooks/useTenant";
import { useCallback } from "react";
import { useHistory } from "react-router-dom";

const useNavigationHelper = () => {
  const history = useHistory();
  const { activeTenant } = useTenant();

  const navigateToProfile = useCallback(
    (organisationUserId: string) => {
      history.push(`/${activeTenant}/users/${organisationUserId}`);
    },
    [history, activeTenant]
  );

  const navigateToDepartment = useCallback(
    (departmentId: string) => {
      history.push(`/${activeTenant}/departments/${departmentId}`);
    },
    [history, activeTenant]
  );

  const navigateToFeed = useCallback(() => {
    history.push(`/${activeTenant}/feed`);
  }, [history, activeTenant]);

  const navigateToPostDetail = useCallback(
    (postId: string) => {
      history.push(`/${activeTenant}/posts/${postId}`);
    },
    [history, activeTenant]
  );

  const navigateToTagsFeed = useCallback(
    (tag: string) => {
      const tagUrl = tag.replace("#", "");
      history.push(`/${activeTenant}/tags/${tagUrl}`);
    },
    [history, activeTenant]
  );

  const navigateToGroups = useCallback(() => {
    history.push(`/${activeTenant}/groups`);
  }, [activeTenant, history]);

  const navigateToGroupCategory = useCallback(
    (category: string) => {
      history.push(`/${activeTenant}/groups-category/${category}`);
    },
    [activeTenant, history]
  );

  const navigateToGroup = useCallback(
    (groupId: string) => {
      history.push(`/${activeTenant}/groups/${groupId}`);
    },
    [history, activeTenant]
  );

  const navigateToGroupUsers = useCallback(
    (groupId: string) => {
      history.push(`/${activeTenant}/groups/${groupId}/users`);
    },
    [history, activeTenant]
  );

  const navigateToDepartmentUsers = useCallback(
    (departmentId: string) => {
      history.push(`/${activeTenant}/departments/${departmentId}/users`);
    },
    [history, activeTenant]
  );

  const navigateToChat = useCallback(() => {
    history.push(`/${activeTenant}/chat/first`);
  }, [history, activeTenant]);

  const navigateToChatChannel = useCallback(
    (channelId: string) => {
      history.push(`/${activeTenant}/chat/${channelId}`);
    },
    [history, activeTenant]
  );

  const navigateToSurvey = useCallback(
    (surveyId: string) => {
      history.push(`/${activeTenant}/surveys/${surveyId}`);
    },
    [history, activeTenant]
  );

  const navigateToCoursePreview = useCallback(
    (previewId: string) => {
      history.push(`/${activeTenant}/courses/previews/${previewId}`);
    },
    [history, activeTenant]
  );

  const navigateToCourseWorkflow = useCallback(
    (courseId: string, moduleId: string) => {
      history.push(`/${activeTenant}/workflow/${courseId}/module/${moduleId}`);
    },
    [history, activeTenant]
  );

  const navigateToNotifications = useCallback(() => {
    history.push(`/${activeTenant}/notifications`);
  }, [history, activeTenant]);

  const navigateToCourse = useCallback(
    (courseId: string) => {
      history.push(`/${activeTenant}/courses/${courseId}`);
    },
    [history, activeTenant]
  );

  const navigateToCoursesDirectory = useCallback(
    (tabId?: string) => {
      history.push(`/${activeTenant}/courses${tabId ? `#${tabId}` : ""}`);
    },
    [history, activeTenant]
  );

  const navigateToUnwatchedReels = useCallback(() => {
    history.push(`/${activeTenant}/unwatched-reels`);
  }, [history, activeTenant]);

  const navigateToImportantPosts = useCallback(() => {
    history.push(`/${activeTenant}/important-posts`);
  }, [history, activeTenant]);

  const navigateToKnowledgeRequests = useCallback(() => {
    history.push(`/${activeTenant}/knowledge-requests`);
  }, [history, activeTenant]);

  const navigateToPendingKnowledgeRequests = useCallback(() => {
    history.push(`/${activeTenant}/pending-knowledge-requests`);
  }, [history, activeTenant]);

  const navigateToPendingAnswers = useCallback(() => {
    history.push(`/${activeTenant}/pending-answers`);
  }, [history, activeTenant]);

  const navigateToReelsDirectory = useCallback(() => {
    history.push(`/${activeTenant}/reels`);
  }, [history, activeTenant]);

  const navigateToPasswordPage = useCallback(() => {
    history.push(`/${activeTenant}/password`);
  }, [history, activeTenant]);

  return {
    navigateToProfile,
    navigateToPostDetail,
    navigateToFeed,
    navigateToDepartment,
    navigateToTagsFeed,
    navigateToGroup,
    navigateToGroups,
    navigateToGroupCategory,
    navigateToGroupUsers,
    navigateToChat,
    navigateToChatChannel,
    navigateToNotifications,
    navigateToCoursesDirectory,
    navigateToCourse,
    navigateToCourseWorkflow,
    navigateToSurvey,
    navigateToCoursePreview,
    navigateToDepartmentUsers,
    navigateToUnwatchedReels,
    navigateToImportantPosts,
    navigateToKnowledgeRequests,
    navigateToPendingKnowledgeRequests,
    navigateToPendingAnswers,
    navigateToReelsDirectory,
    navigateToPasswordPage
  };
};

export { useNavigationHelper };
