import { Box } from "@mui/material";
import { colors } from "@talkouttech/portal-shared";
import { Logo } from "assets/Logo";
import { InfoBox } from "components/InfoBox";
import { useAuth } from "context/auth-context";
import { ChangePasswordForm } from "presentation/Worlds/components/ChangePasswordForm";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Container } from "./PasswordChangeRequired.styles";

export const PasswordChangeRequired: FC = () => {
  const { t } = useTranslation();
  const { refreshAuth0TokenWithoutCache } = useAuth();

  const onPasswordChanged = () => {
    refreshAuth0TokenWithoutCache?.();
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" pt={10} gap={2}>
      <Container display="flex" flexDirection="column" alignItems="center" gap={4} p={4} maxWidth={400}>
        <Box display="flex" width={300} justifyContent="center">
          <Logo darkText id="logo" width={200} height={50} />
        </Box>

        <Box display="flex" maxWidth={300}>
          <InfoBox color={colors.orange} textVariant="caption" info={t("passwordChangeRequired.info")} />
        </Box>

        <ChangePasswordForm hideHeader width={300} buttonVariant="contained" successCallback={onPasswordChanged} />
      </Container>
    </Box>
  );
};
