import { TextField, InputAdornment } from "@mui/material";
import { theme } from "@talkouttech/portal-shared";
import { FC, useState } from "react";
import { Control, Controller } from "react-hook-form";
import { IoMdEye, IoMdEyeOff } from "react-icons/io";

interface PasswordFormFieldProps {
  control: Control<any>;
  name: string;
  defaultValue: string;
  label: string;
  placeholder: string;
  error?: boolean;
  helperText?: string;
}

const PasswordFormField: FC<PasswordFormFieldProps> = ({
  control,
  name,
  defaultValue,
  label,
  placeholder,
  error,
  helperText
}) => {
  const [passwordMasked, setPasswordMasked] = useState(true);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ onChange, value }) => (
        <TextField
          type={passwordMasked ? "password" : "text"}
          placeholder={placeholder}
          name={name}
          label={label}
          fullWidth
          onChange={onChange}
          value={value}
          error={error}
          helperText={helperText}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {passwordMasked ? (
                  <IoMdEye
                    style={{ cursor: "pointer" }}
                    color={theme.colors.black}
                    size={20}
                    onClick={() => setPasswordMasked(false)}
                  />
                ) : (
                  <IoMdEyeOff
                    style={{ cursor: "pointer" }}
                    color={theme.colors.black}
                    size={20}
                    onClick={() => setPasswordMasked(true)}
                  />
                )}
              </InputAdornment>
            )
          }}
        />
      )}
    />
  );
};

export { PasswordFormField };
