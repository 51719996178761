import { LocalStorageCache, RedirectLoginOptions, useAuth0 } from "@auth0/auth0-react";
import { useTenantFeaturesWithoutAppClient } from "application/features";
import { FullPageLoader } from "components/ui/FullPageLoader";
import { useToast } from "context/toast-context";
import { Identity } from "domain/user";
import { useBrowserId } from "hooks/useBrowserId";
import { parse } from "query-string";
import * as React from "react";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { client } from "utils/api-client";
import { getIdentyFromToken } from "utils/user-utils";
import { v4 as uuid } from "uuid";

type TokenRefreshResonse = {
  access_token: string;
  refresh_token: string;
  expires_in: number;
  scope: string;
  id_token: string;
  token_type: string;
};

export type AuthContextType = {
  identity?: Identity | null;
  isLoading?: boolean;
  isAuthenticated?: boolean;
  logout?: (location?: string) => void;
  loginWithAuth0Redirect?: (options?: RedirectLoginOptions) => void;
  i18n?: unknown;
  setImpersonationToken?: (token: string | null) => void;
  authCheckRequested?: (token?: string) => void;
  isImpersonating?: boolean;
  refreshAuth0TokenWithoutCache?: () => void;
};

const AuthContext = React.createContext<AuthContextType>({});
// 👇 This is for loging readybility
AuthContext.displayName = "AuthContext";

const ANON_KEY = "a";

const AuthProvider: React.FC = props => {
  const [identity, setIdentity] = React.useState<Identity | null>(null);

  const { key, __s_t: newSessionToken, __m } = parse(window.location.search); // this checks if we are during invive passwordless flow

  const shouldStartAnonymousSession = __m === ANON_KEY;

  const [impersonationToken, setImpersonationTokenInternal] = React.useState<string | null>(null);
  const [isPasswordlessLoading, setIsPasswordlessLoading] = React.useState(true);
  const deviceId = useBrowserId();
  const { isAuthenticated, getAccessTokenSilently, logout: auth0Logout, isLoading, loginWithRedirect } = useAuth0();
  const { i18n } = useTranslation();

  const logout = React.useCallback(
    (location?: string) => {
      const activeTenant = localStorage.getItem("activeTenant") ?? "";

      if (identity?.token && identity?.tenantInfo[activeTenant]) {
        client(
          `tenants/${activeTenant}/profiles/${
            identity?.tenantInfo[activeTenant].organisationUserId ?? ""
          }/push/tokens/${deviceId}`,
          {
            token: identity?.token,
            method: "DELETE"
          }
        );
      }

      localStorage.removeItem("clientId");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("sessionToken");
      localStorage.removeItem("tempToken");
      localStorage.removeItem("activeTenant");

      setIsPasswordlessLoading(false);
      auth0Logout({ returnTo: location || window.location.origin });
    },
    [auth0Logout, deviceId, identity?.tenantInfo, identity?.token]
  );

  const loginWithAuth0Redirect = React.useCallback(
    (options?: RedirectLoginOptions) => {
      localStorage.removeItem("clientId");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("sessionToken");

      loginWithRedirect(options);
    },
    [loginWithRedirect]
  );

  const handleSettingIdentity = React.useCallback((token: string) => {
    const identity = getIdentyFromToken(token);

    setIdentity(identity);
    setIsPasswordlessLoading(false);
  }, []);

  const getTokenFromRefreshToken = React.useCallback(
    async (refreshToken: string) => {
      if (key !== null && key !== undefined) {
        return;
      }
      const clientIdOverride = localStorage.getItem("clientId");
      const shouldUsePasswordlessRefresh = !!clientIdOverride && clientIdOverride !== window.APP_CONFIG.AUTH0_CLIENTID;

      try {
        const data = await client<TokenRefreshResonse>(
          shouldUsePasswordlessRefresh ? `auth/refresh` : "",
          {
            method: "POST",

            data: shouldUsePasswordlessRefresh
              ? {
                  clientId: clientIdOverride,
                  refreshToken
                }
              : {
                  grant_type: "refresh_token",
                  client_id: window.APP_CONFIG.AUTH0_CLIENTID,
                  refresh_token: refreshToken,
                  scope: `openid offline_access profile email appenv:${window.APP_CONFIG.ENVIRONMENT_NAME}`,
                  audience: window.APP_CONFIG.AUTH0_AUDIENCE,
                  redirectUri: `${window.location.origin}/home`
                }
          },
          shouldUsePasswordlessRefresh ? undefined : `https://${window.APP_CONFIG.AUTH0_DOMAIN}/oauth/token`
        );
        localStorage.setItem("refreshToken", data.refresh_token);
        return data.access_token;
      } catch (error) {
        return undefined;
      }
    },
    [key]
  );

  const refreshAuth0Token = useCallback(
    (ignoreCache?: boolean) => {
      getAccessTokenSilently(ignoreCache ? { ignoreCache: true } : undefined).then(token => {
        handleSettingIdentity(token);

        // Save Auth0 refresh token to enable easier use later
        const tokenCache = new LocalStorageCache();
        const key = tokenCache.allKeys().find(key => key.includes("auth0spa"));
        if (key) {
          const cachedValue = tokenCache.get(key) as any;
          const refreshToken = cachedValue?.body?.refresh_token;
          if (refreshToken) {
            localStorage.setItem("refreshToken", refreshToken);
          }
        }

        return;
      });
    },
    [getAccessTokenSilently, handleSettingIdentity]
  );

  const authCheckRequested = useCallback(
    async (token?: string) => {
      setIsPasswordlessLoading(true);
      const tempToken = localStorage.getItem("tempToken");
      if (tempToken && token) {
        client(`auth/link`, { token, method: "POST", data: { secondaryUserAccessToken: tempToken } }).then(() => {
          localStorage.removeItem("tempToken");
        });
      }
      if (impersonationToken) {
        handleSettingIdentity(impersonationToken);
        return;
      } else if (token) {
        handleSettingIdentity(token);
        return;
      } else if (isAuthenticated) {
        refreshAuth0Token();
      }

      let sessionToken = newSessionToken;
      if (shouldStartAnonymousSession) {
        const pathParts = window.location.pathname.split("/").filter(p => !!p);
        if (pathParts.length > 1) {
          const tenantId = pathParts[0];
          let externalId = localStorage.getItem("anon_external_id");
          if (!externalId) {
            externalId = uuid();
            localStorage.setItem("anon_external_id", externalId);
          }

          try {
            const sessionResult = await client<{ sessionToken: string }>("anonymous/sessions", {
              method: "POST",
              data: {
                tenantId,
                externalId
              }
            });

            sessionToken = sessionResult.sessionToken;
          } catch (error) {
            // Ignore
          }
        }
      }

      if (
        !!sessionToken &&
        sessionToken !== localStorage.getItem("sessionToken") &&
        sessionToken !== localStorage.getItem("refreshToken")
      ) {
        localStorage.setItem("sessionToken", sessionToken as string);
        localStorage.setItem("clientId", window.APP_CONFIG.PREAUTH_CLIENT_ID);
        localStorage.setItem("refreshToken", sessionToken as string);
      }

      const refreshToken = localStorage.getItem("refreshToken");
      if (!refreshToken) {
        setIsPasswordlessLoading(false);
        return;
      }

      const tokenFromRefToken = await getTokenFromRefreshToken(refreshToken);
      if (!tokenFromRefToken) {
        setIsPasswordlessLoading(false);
        return;
      }

      handleSettingIdentity(tokenFromRefToken);
    },
    [
      getTokenFromRefreshToken,
      handleSettingIdentity,
      impersonationToken,
      isAuthenticated,
      newSessionToken,
      refreshAuth0Token,
      shouldStartAnonymousSession
    ]
  );

  React.useEffect(() => {
    if (!isAuthenticated) {
      authCheckRequested();
    } else {
      getAccessTokenSilently().then(token => {
        const tempToken = localStorage.getItem("tempToken");
        if (!tempToken) {
          authCheckRequested();
        } else {
          client(`auth/link`, { token, method: "POST", data: { secondaryUserAccessToken: tempToken } }).then(() => {
            localStorage.removeItem("tempToken");
            const identity = getIdentyFromToken(token);
            setIdentity(identity);
          });
        }
      });
    }
  }, [authCheckRequested, getAccessTokenSilently, isAuthenticated]);

  if (isLoading) return <FullPageLoader />;

  const value: AuthContextType = {
    isAuthenticated: identity !== null,
    logout,
    identity,
    isLoading: isPasswordlessLoading || isLoading,
    loginWithAuth0Redirect,
    i18n,
    authCheckRequested,
    setImpersonationToken: (token: string | null) => {
      setImpersonationTokenInternal(token);
    },
    isImpersonating: !!impersonationToken || !!identity?.isImpersonated,
    refreshAuth0TokenWithoutCache: () => refreshAuth0Token(true)
  };
  return <AuthContext.Provider value={value} {...props} />;
};

const useAuth = () => {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }
  return context;
};

function useClient<T>() {
  const { identity } = useAuth();
  const { showSnackBar } = useToast();

  const token = identity?.token;
  const { features } = useTenantFeaturesWithoutAppClient(token);
  const clientApiVersion = features.apiVersion;

  return React.useCallback(
    (endpoint: string, config?) =>
      client<T>(endpoint, { ...config, token, showSnackBar, apiVersion: clientApiVersion }),
    [token, showSnackBar, clientApiVersion]
  );
}

export { AuthProvider, useAuth, useClient };
